@media screen and (max-width: 500px) {
  #Svitlov > img {
    width: 100%;
  }
}
@media screen and (max-width: 824px) {
  h3 {
    margin-top: 0;
  }
  .topBg > grid-container {
    width: 100%;
  }

  #infoInfo > grid-container {
    width: 100%;
  }

  .menuBg > grid-container {
    width: 100%;
    font-size: 1em;
    padding-top: 2em;
  }
  .menuBg > hr {
    bottom: -1.2em;
  }
  #menuBg {
    height: 5.1em;
    bottom: -1.9em;
  }
  .infoBg {
    height: 80em;
  }
  .infoBg > grid-container {
    grid-template-columns: 1fr;
    grid-template-areas: "vid1" "vid2";
    row-gap: 2em;
  }
  .portfolioContainer,
  .infoBg > grid-container {
    width: 95%;
    margin: 0 auto;
  }
  .containerC {
    display: grid;
    width: 60vw;
    height: 30vw;
  }
  .containerJ {
    width: 45vw;
    height: 74vw;
  }
  .portfolioBg {
    height: 50em;
  }
  .portfolioContainer {
    background: #fff;
    height: 45em;
    margin-top: 1em;
  }

  .portfolioBack,
  .portfolioContainer::after {
    display: none;
  }
  .portfolioContainer > grid-container {
    width: 90%;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 0em;
    justify-items: center; /*IMPORTANT*/
  }

  .container {
    width: 80vw;
    height: 40vw;
  }
  .popup {
    position: fixed;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 999;
  }
  .textField {
    width: 70%;
  }

  .Footer > grid-container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
  .hr {
    width: 20%;
  }
  #infoInfo {
    grid-template-columns: 1fr;
    row-gap: 2em;
    grid-template-areas:
      "cs"
      "csT"
      "java"
      "javaT"
      "python"
      "pythonT";
  }
}
