@font-face {
  font-family: "Lombok";
  src: url(/src/Lombok.ttf);
}
body {
  margin: 0 auto;
  overflow-x: hidden;
}
.relative {
  margin: 0 auto;
  position: relative;
}
.App {
  font-family: "Lato";
  text-align: center;
}
.topBg {
  height: 25rem;
  font-family: "Lato", sans-serif;
  width: 100%;
  background: rgb(202, 160, 119);
  background: linear-gradient(
    0deg,
    rgba(202, 160, 119, 1) 0%,
    rgba(235, 177, 111, 1) 100%
  );
}
.topBg > img {
  transform: scale(0.6) translateY(-1%);
  position: absolute;
  top: 18em;
  right: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 5;
  animation: avatar 10s infinite, hr 1s 1;
}
@keyframes hr {
  50% {
    transform: scale(0.4);
  }
  99% {
    transform: scale(0.6);
  }
}
@keyframes avatar {
  10% {
    transform: scale(0.6);
  }
  25% {
    transform: scale(0.7) translateY(-1%);
  }
  50% {
    transform: scale(0.7) translateY(-20%);
  }
  75% {
    transform: scale(0.7) translateY(-1%);
  }
}
.topBg > grid-container {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  width: 50%;
  display: grid;
  grid-template-columns: 1fr 1fr;

  margin-top: 8em;
  font-size: 1.6em;
}
.topBg > grid-container > grid-item > a {
  text-decoration: none;
  color: white;
}
.topBg > grid-container > grid-item > a:hover {
  transform: scale(2em);
}

#Svitlov {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 1em;
  font-size: 5em;
  color: white;
}
#Svitlov > img {
  transform: scale(0.4);
}
.topCircle {
  position: absolute;
  width: 8rem;
  height: 8rem;
  background: #fdc689;
  border-radius: 50%;
  top: 18rem;
  right: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 2;
  animation: circle 0.8s 1;
}
@keyframes circle {
  50% {
    transform: scale(0.2) translateY(-10%);
  }
}
.bigger {
  background: #f68e56;
  top: 14rem;
  width: 16em;
  height: 16em;
  z-index: 1;
  animation-delay: 0.2s;
}

.ovalWrap {
  overflow: hidden;
  width: 100%;
  padding-top: 21em;
  height: 9em;
}
.topOval {
  position: relative;
  z-index: 3;

  right: 10%;
  width: 120%;
  height: 12em;

  background: white;
  -webkit-clip-path: ellipse(23% 50% at 50% 50%);
  clip-path: ellipse(50% 50% at 50% 50%);
}
.topTriangle {
  background: #fdc689;
  width: 4em;
  height: 3.5em;
  position: absolute;
  z-index: 4;
  top: 21em;
  right: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  clip-path: polygon(100% 0, 0 0, 50% 100%);
}
.infoBg {
  height: 45rem;
  margin-top: 8em;
  width: 100%;
  background: #fff;
}
.infoBg > grid-container {
  margin: 0 auto;
  width: 50%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "vid1 vid2";
  column-gap: 2em;
}
@media screen and (max-width: 1124px) {
  .infoBg > grid-container {
    width: 100%;
  }
}
.infoBg > grid-container:nth-child(1) {
  grid-area: vid1;
}
.infoBg > grid-container:nth-child(2) {
  grid-area: vid2;
}

#infoInfo {
  text-align: center;
  padding-top: 6em;
  display: grid;
  width: 50%;
  margin: 0 auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  row-gap: 1em;
  column-gap: 1em;
  font-size: 0.9em;
  min-height: 2em;
  max-height: 5em;
  grid-template-areas:
    "cs java python"
    "csT javaT pythonT";
}
#infoInfo > grid-item:nth-child(1) {
  grid-area: cs;
}
#infoInfo > grid-item:nth-child(2) {
  grid-area: java;
}
#infoInfo > grid-item:nth-child(3) {
  grid-area: python;
}
#infoInfo > grid-item:nth-child(4) {
  grid-area: csT;
}
#infoInfo > grid-item:nth-child(5) {
  grid-area: javaT;
}
#infoInfo > grid-item:nth-child(6) {
  grid-area: pythonT;
}
.vid {
  width: 320px;
  height: 181px;
  margin: 0 auto;
  background: #333;
}
.infoCircle {
  position: relative;
  width: 0.4rem;
  height: 0.4rem;
  background: #555555;
  border-radius: 50%;
  border: 0.3rem solid #fdc689;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1em;
}
#hello {
  font-size: 1.4em;
}
#myProjects {
  font-size: 1.4rem;
}
.menuBg {
  position: relative;
  z-index: 1;
  height: 5em;
  width: 100%;
  background: #363636;
}
#menuBg {
  width: 25%;
  position: absolute;
  z-index: -1;
  height: 5rem;
  background: rgb(243, 167, 91);
  bottom: -1.1em;
  transition-duration: 0.3s;
  /* transform: scaleX(1.5); */
  /* transform: translateX(100%); */
}
#menuTr {
  width: 25%;
  position: absolute;
  z-index: -1;
  height: 5rem;
  background: rgb(0, 0, 0, 0);
  bottom: -1.9em;
  /* transform: scaleX(1.5); */
  /* transform: translateX(100%); */
}

.menuBg > grid-container {
  position: relative;
  z-index: 1;
  padding-top: 1.2em;
  width: 50%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  color: white;
  font-size: 1.4em;
}

.menuBg > grid-container > grid-item {
  cursor: pointer;
}
.menuBg > hr {
  width: 45%;
  position: relative;
  border: none;
  z-index: 999;
  background: white;
  margin-left: 20%;
  bottom: -1em;
  height: 0.2em;
  transition-duration: 1s;
}

.bigDiamond {
  width: 100%;
}
.bigDiamond > img {
  transform: scale(0.8);
  margin-top: 6em;
  margin-left: -50%;
  margin-right: 0%;
}
.portfolioBg {
  height: 46em;
  width: 100%;
  background: rgb(85, 85, 85);
  background: linear-gradient(
    0deg,
    rgba(85, 85, 85, 1) 0%,
    rgba(62, 62, 62, 1) 100%
  );
  overflow: hidden;
}
.portfolioContainer {
  position: absolute;
  overflow: hidden;
  width: 50%;
  height: 42em;
  margin-top: 2em;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 2;
}
.portfolioContainer::after {
  content: "";
  position: absolute;
  width: 200%;
  height: 350%;
  top: 20px;
  right: 15%;
  background: rgba(255, 255, 255, 1);
  transform-origin: 62% 0;
  transform: rotate(-45deg);
  z-index: -1;
}
.portfolioBack {
  position: absolute;
  transform: scale(0.99);
  overflow: hidden;
  background: #ccc;
  width: 50%;
  height: 26em;
  margin-top: 2em;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
}
#envelope {
  margin-top: 2em;
  font-style: italic;
  font-size: 1.2em;
}
#cSharpWrapper {
  margin: 0 auto;
  padding-top: 2em;
  display: grid;
  width: 90%;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  justify-items: center; /*IMPORTANT*/
}

.containerC {
  display: grid;
  width: 40vw;
  height: 20vw;
  grid-gap: 0.2rem;
  grid-template-areas:
    "content-1 content-1 content-2"
    "content-1 content-1 content-2";
}
.content-1 {
  grid-area: content-1;
}
.content-2 {
  grid-area: content-2;
}
.containerJ {
  display: grid;
  width: 14vw;
  height: 43vh;
  grid-gap: 0.2rem;
  grid-template-columns: 1fr;
}
.brickC {
  width: 100%;
  height: 100%;
}
.brickJ {
  width: 100%;
  height: 200%;
}
.contactBg {
  height: 52rem;
  width: 100%;
  background: #fff;
}
.doc {
  width: 100%;
  height: 30em;
}
.popupWrapper {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgb(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 999;
}
.popup {
  position: fixed;
  width: 40%;
  height: 100vh;
  overflow: hidden;
  top: 10%;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 999;
}
.popupinner {
  padding: 10% 0 10% 0;
  margin: auto;
  background: #333;
  color: white;
  z-index: 999;
}
.textField {
  width: 20%;
}
.Footer {
  width: 100%;
  height: 10rem;
  background: #1f1f1f;
  position: absolute;
}
.Footer > img {
  transform: scale(0.6) rotate(180deg);
  cursor: pointer;
  position: absolute;
  top: -4.5em;
  right: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 5;
  animation: up 4s infinite;
}
@keyframes up {
  10% {
    transform: scale(0.6) rotate(180deg) translateY(1em);
  }
  20% {
    transform: scale(0.6) rotate(180deg);
  }
}
.Footer > grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: 3em;
  column-gap: 2em;
  left: 0;
  right: 0;
  margin: 0 auto;
  color: white;
}
.Footer > grid-container > #mediaIcons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  transform: scale(0.8);
}
.Footer > grid-container > #svitlov {
  padding-top: 1em;
  display: grid;
  grid-template-columns: 1fr;
  right: 0;
}
.Footer > a {
  text-decoration: none;
  color: #aaa;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 8.5rem;
  font-size: 0.7rem;
}
.bottomTriangle {
  background: #fff;
  width: 4em;
  height: 1.4em;
  position: absolute;
  z-index: 4;
  right: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  clip-path: polygon(100% 0, 0 0, 50% 100%);
}
.hr {
  background-color: #1f1f1f;
  height: 2px;
  width: 25%;
  border: 0;
  position: absolute;
  top: -1.5em;
}
.l {
  left: 20%;
}
.r {
  right: 20%;
}
